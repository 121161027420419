






































import { defineComponent, useContext, PropType } from '@nuxtjs/composition-api';
import { SfCarousel, SfCategoryCard, SfButton } from '@storefront-ui/vue';
import useBem from '~/composables/useBem';
import { RsgIntersectionObserver, RsgIntersectionObserverEmit } from '~/components/intersection-observer';
import { CategoryListType } from './types';

export default defineComponent({
  name: 'CategoryList',
  components: {
    SfButton,
    SfCarousel,
    SfCategoryCard,
    RsgIntersectionObserver,
  },
  props: {
    list: {
      type: Array as PropType<CategoryListType[]>,
      required: true,
    },
  },
  emits: ['card-click', 'card-viewing'],
  setup(_, { emit }) {
    const b = useBem();
    const { app } = useContext();
    const { isDesktop } = app.$device;

    const carouselSetting = {
      type: 'slider',
      startAt: 0,
      perView: 4,
      gap: 12,
      breakpoints: {
        1023: {
          peek: 0,
          perView: 2,
          gap: 8,
        },
      },
    };

    const handleCardClick = (item) => {
      emit('card-click', item);
    };

    const handleCardViewing = (observerData: RsgIntersectionObserverEmit, item) => {
      emit('card-viewing', {
        ...observerData,
        ...item,
      });
    };

    return {
      b,
      carouselSetting,
      isDesktop,
      handleCardClick,
      handleCardViewing,
    };
  },
});








import {
  defineComponent, ref, PropType, onMounted, onBeforeUnmount,
} from '@nuxtjs/composition-api';
import type { RsgIntersectionObserverEmit } from './useIntersectionObserver';

export default defineComponent({
  name: 'RsgIntersectionObserver',

  props: {
    root: {
      type: Object as PropType<HTMLElement | typeof Document>,
      default: null,
    },
    rootMargin: {
      type: [String, Number] as PropType<string | number>,
      default: '0px',
    },
    threshold: {
      type: [Array, Number] as PropType<Array<number> | number>,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const observer = ref<IntersectionObserver | null>(null);
    const elRef = ref<HTMLElement | null>(null);

    const options = {
      root: props.root,
      rootMargin: typeof props.rootMargin === 'number' ? `${props.rootMargin}px` : props.rootMargin,
      threshold: props.threshold,
    };

    const unobserve = () => {
      if (observer.value && elRef.value) {
        observer.value.unobserve(elRef.value);
      }
    };

    onMounted(() => {
      observer.value = new IntersectionObserver((entries) => {
        emit('on-change', { entry: entries[0], unobserve } as RsgIntersectionObserverEmit);
      }, options);

      observer.value.observe(elRef.value);
    });

    onBeforeUnmount(() => {
      if (observer.value) {
        unobserve();
        observer.value = null;
      }
    });

    return {
      elRef,
    };
  },
});


























































































































































































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
  computed,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import {
  SfBanner,
  SfHero,
  SfArrow,
  SfButton,
  // SfImage,
} from '@storefront-ui/vue';
import { CmsPage, SubscriptionStatusesEnum } from '~/modules/GraphQL/types';
import ContentWrapper from '~/components/utils/ContentWrapper.vue';
// import SbannerSection from '~/components/BannerSection.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent, useGTM } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { CategoryList, CategoryListType } from '~/components/CategoryList';
import { RsgIntersectionObserver, RsgIntersectionObserverEmit } from '~/components/intersection-observer';
import useNewsletter from '../composables/useNewsletter';

export default defineComponent({
  name: 'HomePage',

  components: {
    RsgIntersectionObserver,
    // SbannerSection,
    LoadWhenVisible,
    ContentWrapper,
    SfHero,
    SfBanner,
    SfArrow,
    SfButton,
    // SfImage,
    CategoryList,
    // CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    // MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
    NewProducts: () => import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
  },
  layout: 'fullwidth',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const { updateSubscription, status: newsletterStatus, error: newsletterError } = useNewsletter();
    const { isDesktop } = app.$device;
    const { trackEvent } = useGTM();

    const page = ref<CmsPage | null>(null);
    const sliderHeroes = ref([
      {
        id: 'lQ9PF4fBYmGKqFKF6q8KL',
        name: 'Summer mood promotion',
        title: 'Mood: summer',
        buttonText: 'За покупками',
        notice: 'настроение: лето',
        background: 'rgb(5, 64, 84)',
        image: {
          desktop: '/homepage/heroes/hq/banner-summer-mood.webp',
          mobile: '/homepage/heroes/hq/banner-summer-mood-mob.webp',
        },
        link: '/full-products-category',
        className:
          'sf-hero-item--position-bg-center sf-hero-item--align-left',
      },
      {
        id: 'bK9AS6d1mg3DNl0ulr25l',
        name: 'Young and free spirited',
        title: 'Молодые<br>свободные духом',
        buttonText: 'за покупками',
        background: 'rgb(5, 64, 84)',
        image: {
          desktop: '/homepage/heroes/hq/banner1.webp',
          mobile: '/homepage/heroes/hq/banner1-mob.webp',
        },
        link: '/molodye-svobodnye-duhom',
        className:
          'sf-hero-item--position-bg-center sf-hero-item--align-left',
      },
      {
        id: 'tqHmghVe2xQ5zWttaYieO',
        name: 'New jeans collection',
        title: 'HEY! IT’S NEW<br/>DENIM',
        buttonText: 'за покупками',
        notice: 'Привет! <br />это новый деним',
        noticeDifferentStyle: true,
        background: 'rgb(5, 64, 84)',
        image: {
          desktop: '/homepage/heroes/hq/banner-denim.webp',
          mobile: '/homepage/heroes/hq/banner-denim-mob.webp',
        },
        link: '/dzhinsy-dlja-devushek-i-parnej',
        className:
          'sf-hero-item--position-bg-center sf-hero-item--align-left',
      },
    ]);

    const heroes = ref([
      {
        id: 'qKZCsSZlHf1YCYXAXg9Nu',
        name: 'New stores',
        title: 'Welcome<br/>to the new stores',
        buttonText: 'События',
        notice: '*ДОБРО ПОЖАЛОВАТЬ В НОВЫЕ МАГАЗИНЫ',
        background: 'rgb(5, 64, 84)',
        link: 'https://promotions.readysteadygo.ru/',
        image: {
          desktop: '/homepage/heroes/hq/banner-stores-2.webp',
          mobile: '/homepage/heroes/hq/banner-stores-mob-2.webp',
        },
        className: 'align-left',
      },
    ]);

    const hero = ref({
      title: app.i18n.t('Make yourself known!'),
      subtitle: app.i18n.t('New2'),
      buttonText: app.i18n.t('For new products'),
      imageSrc: '/homepage/bannerB.webp',
      imageWidth: isDesktop ? 1284 : 1284,
      imageHeight: isDesktop ? 621 : 621,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
      link: '/women.html',
    });

    const categoryList = ref<CategoryListType[]>([
      {
        id: 'category-tops',
        label: 'Топы',
        name: 'Category Tops',
        link: '/girls/tops',
        background: {
          mobile: '/homepage/category-list/category-mobile-1-v2.jpg',
          desktop: '/homepage/category-list/category-desktop-1-v2.jpg',
        },
      },
      {
        id: 'category-pants',
        label: 'Брюки',
        name: 'Category Pants',
        link: '/girls/pants',
        background: {
          mobile: '/homepage/category-list/category-mobile-2-v2.jpg',
          desktop: '/homepage/category-list/category-desktop-2-v2.jpeg',
        },
      },
      {
        id: 'category-shorts',
        label: 'Шорты',
        name: 'Category Shorts',
        link: '/boys/shorts',
        background: {
          mobile: '/homepage/category-list/category-mobile-3-v2.jpg',
          desktop: '/homepage/category-list/category-desktop-3-v2.jpeg',
        },
      },
      {
        id: 'category-shirts',
        label: 'Рубашки',
        name: 'Category Shirts',
        link: '/boys/shirts',
        background: {
          mobile: '/homepage/category-list/category-mobile-4-v2.jpg',
          desktop: '/homepage/category-list/category-desktop-4-v2.jpeg',
        },
      },
    ]);

    const bannerbottom = ref({
      title: app.i18n.t('unique hits!'),
      subtitle: app.i18n.t('Best'),
      buttonText: app.i18n.t('For new products'),
      imageSrc: '/homepage/BannerBottom.webp',
      imageSrcMobile: '/homepage/BannerBottomMobile.webp',
      imageWidth: isDesktop ? 1284 : 1500,
      imageHeight: isDesktop ? 564 : 1529,
      imageWidthMobile: isDesktop ? 1500 : 1500,
      imageHeightMobile: isDesktop ? 1529 : 1529,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
      link: '/women.html',
    });
    const banners = ref([
      {
        slot: 'banner-A',
        subtitle: app.i18n.t('Dresses'),
        title: app.i18n.t('Cocktail & Party'),
        description: app.i18n.t(
          'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
        ),
        buttonText: app.i18n.t('Shop now'),
        image: {
          mobile:
            '/homepage/bannerB.webp',
          desktop:
            '/homepage/bannerF.webp',
        },
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 840 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim desktop-only',
        link: '/women/women-clothing-skirts',
      },
      {
        slot: 'banner-B',
        subtitle: app.i18n.t('Dresses'),
        title: app.i18n.t('Linen Dresses'),
        description: app.i18n.t(
          'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
        ),
        buttonText: app.i18n.t('Shop now'),
        image: '/homepage/bannerE.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 496 : 328,
          height: isDesktop ? 840 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim banner-central desktop-only',
        link: '/women/women-clothing-dresses',
      },
      {
        slot: 'banner-C',
        subtitle: app.i18n.t('T-Shirts'),
        title: app.i18n.t('The Office Life'),
        image: '/homepage/bannerC.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim banner__tshirt',
        link: '/women/women-clothing-shirts',
      },
      {
        slot: 'banner-D',
        subtitle: app.i18n.t('Summer Sandals'),
        title: app.i18n.t('Eco Sandals'),
        image: '/homepage/bannerG.webp',
        imageConfig: {
          fit: 'cover',
          width: isDesktop ? 332 : 328,
          height: isDesktop ? 400 : 343,
          format: 'webp',
        },
        class: 'sf-banner--slim',
        link: '/women/women-shoes-sandals',
      },
    ]);
    const callToAction = ref({
      title: app.i18n.t('Subscribe to Newsletters'),
      description: app.i18n.t('Find out before anyone else about trends, promotions and discounts'),
      buttonText: app.i18n.t('Subscribe'),
      imageSrc: '/homepage/newsletter.webp',
      imageWidth: isDesktop ? 1240 : 400,
      imageHeight: isDesktop ? 202 : 200,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
    });

    const trackedVisuals = ref([]);
    const heroSliderRef = ref<HTMLDivElement | null>(null);
    const onBannerIntersect = ({
      entry,
      id,
      name,
      creative,
      position,
      uniqueName,
      clickable = true,
    }: RsgIntersectionObserverEmit & {
      id?: number | string
      name?: string
      creative?: string
      position?: number
      uniqueName: string,
      clickable?: boolean
    }) => {
      if (trackedVisuals.value.includes(uniqueName)) {
        return;
      }

      if (entry.intersectionRatio >= 0.25) {
        trackEvent('view_promotion', {
          promotionId: id,
          promotionName: name,
          promotionCreative: creative?.replace(/<br>|<br\/>/gi, ' '),
          promotionPosition: position,

          clickable,
        });

        if (uniqueName) {
          trackedVisuals.value.push(uniqueName);
        }
      }
    };

    const onBannerClick = ({
      bannerLink,
      id,
      name,
      creative,
      position,
    }: {
      bannerLink: string
      id?: string
      name?: string
      creative?: string
      position?: number
    }) => {
      trackEvent('select_promotion', {
        promotionId: id,
        promotionName: name,
        promotionCreative: creative.replace(/<br\/>/gi, ' '),
        promotionPosition: position,

        bannerLink,
      });
    };

    const handleCategoryCardClick = (item) => {
      onBannerClick({
        bannerLink: item.link,
        name: item.label,
        id: item.id,
        creative: item.label,
      });
    };

    const handleCategoryCardViewing = (extendedItem: RsgIntersectionObserverEmit & CategoryListType) => {
      onBannerIntersect({
        ...extendedItem,
        id: extendedItem.id,
        name: extendedItem.label,
        creative: extendedItem.label,
        uniqueName: `${extendedItem.label}-categoryList`,
      });
    };

    const onSubscribe = ({ email }) => {
      updateSubscription({ email });
    };

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    return {
      newsletterStatus: computed(() => newsletterStatus.value),
      newsletterError: computed(() => newsletterError.value),
      heroes,
      sliderHeroes,
      banners,
      categoryList,
      callToAction,
      hero,
      page,
      bannerbottom,
      onSubscribe,
      isDesktop,
      onBannerIntersect,
      onBannerClick,
      heroSliderRef,
      handleCategoryCardClick,
      handleCategoryCardViewing,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
  computed: {
    SubscriptionStatusesEnum() {
      return SubscriptionStatusesEnum;
    },
  },
});

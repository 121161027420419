var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"home__section"},[_c('SfHero',{ref:"heroSliderRef",staticClass:"hero home_section",attrs:{"slider-options":{ autoplay: 3000, rewind: true }},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var go = ref.go;
return [_c('SfArrow',{staticClass:"sf-arrow--left sf-arrow--transparent sf-arrow--no-shadow sf-arrow--rounded",attrs:{"aria-label":'previous',"data-testid":"hero-prev-button"},on:{"click":function($event){$event.stopPropagation();return go()}}})]}},{key:"next",fn:function(ref){
var go = ref.go;
return [_c('SfArrow',{staticClass:"sf-arrow--right sf-arrow--transparent sf-arrow--no-shadow sf-arrow--rounded",attrs:{"aria-label":'next',"data-testid":"hero-next-button"},on:{"click":function($event){$event.stopPropagation();return go()}}})]}}])},[_vm._v(" "),_vm._v(" "),_vm._l((_vm.sliderHeroes),function(img,index){return _c('SfHeroItem',{key:index,class:img.className,attrs:{"background":img.background,"link":img.link},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"sf-hero-item__title-wrapper",staticStyle:{"display":"none"}},[_c('span',{staticClass:"sf-hero-item__title",domProps:{"innerHTML":_vm._s(img.title)}}),_vm._v(" "),(img.buttonText)?_c('SfButton',{staticClass:"sf-hero-item__button color-white",on:{"click":function () { return _vm.onBannerClick({
                bannerLink: img.link,
                id: img.id,
                name: img.name,
                creative: img.title,
                position: index
              }); }}},[_vm._v("\n              "+_vm._s(img.buttonText)+"\n            ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"sf-hero-item__notice",class:{ 'sf-hero-item__notice--different': img.noticeDifferentStyle },domProps:{"innerHTML":_vm._s(img.notice)}})],1)]},proxy:true},{key:"withImgTag",fn:function(){return [_c('RsgIntersectionObserver',{attrs:{"threshold":0.25},on:{"on-change":function (observerData) { return _vm.onBannerIntersect(Object.assign({}, observerData,
              {id: img.id,
              name: img.name,
              creative: img.title,
              position: index,
              uniqueName: img.id})); }}},[_c('img',{staticClass:"sf-hero-item__image",attrs:{"loading":"eager","src":_vm.isDesktop ? img.image.desktop : img.image.mobile,"alt":img.title},on:{"click":function () { return _vm.onBannerClick({
                bannerLink: img.link,
                id: img.id,
                name: img.name,
                creative: img.title,
                position: index
              }); }}})])]},proxy:true}],null,true)})})],2)],1),_vm._v(" "),_c('LoadWhenVisible',[_c('div',{staticClass:"home__section"},[_c('ContentWrapper',[_c('CategoryList',{attrs:{"list":_vm.categoryList},on:{"card-click":_vm.handleCategoryCardClick,"card-viewing":_vm.handleCategoryCardViewing}})],1)],1)]),_vm._v(" "),_c('LoadWhenVisible',[_c('div',{staticClass:"home__section"},[_c('ContentWrapper',[_c('NewProducts',{staticClass:"products",attrs:{"button-text":_vm.$t('See all'),"title":_vm.$t('Bestsellers'),"category-id":"233","link":"/bestsellers"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }